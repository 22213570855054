class OTF_Sticky_Header_JS {
    action = 0;
    $header = null;

    constructor() {
        this.setupValue();
        this.initHeaderSticky();
        this.initRealEstateNav();
    }


    initRealEstateNav() {
        // $('body.property-style-4 .opal-tab-nav > .inner').sticky({
        //     topSpacing: this.top,
        //     wrapperClassName: 'property-nav-sticky'
        // })
    }

    setupValue() {
        if (!this.$header) {
            this.$header = $('#masthead');
        }
        this.action = this.$header.outerHeight() + this.$header.offset().top;
    }

    initHeaderSticky() {
        if(!$('body').hasClass('opal-header-sticky')){
            return;
        }
        let headerSticky = wp.template('opal-header-sticky');
        let menuFull = this.getTemplate('#masthead .mainmenu-skicky');
        let menu = '';
        let $menu = $(menuFull);
        if($menu.length > 0){
            $menu.find('.site-header-cart').remove();
            $menu.find('.menu-item-search').remove();
            $menu.find('.top-menu').css('marginRight','');
            $menu.find('.top-menu > li').css('marginRight', '');
            menu = $menu.get(0).outerHTML;
        }
        $('body').append(headerSticky({
            logo    : this.getTemplate('#masthead .site-branding'),
            menuFull: menuFull,
            menu    : menu,
            account : this.getTemplate('#masthead .site-header-account'),
            cart    : this.getTemplate('#masthead .site-header-cart'),
            wishlist: this.getTemplate('#masthead .site-header-wishlist'),
            search  : this.getTemplate('#masthead .site-header-search'),
            itemCustom : this.getTemplate('#masthead .sticky-item-custom'),
        }))

        this.addEventSticky();
    }

    addEventSticky(){
        $(window).scroll(() => {
            let $headerSticky = $('#opal-header-sticky');
            if ($(window).scrollTop() + 10 > this.action) {
                if (!$headerSticky.hasClass('sticky-show')) {
                    $headerSticky.addClass('sticky-show');
                }
            } else {
                $headerSticky.removeClass('sticky-show')
            }
        });
    }

    getTemplate(selector) {
        let $selector = $(selector);
        if ($selector.length > 0) {
            return $selector.get(0).outerHTML;
        }
        return '';
    }
}

$(window).load(() => {
    new OTF_Sticky_Header_JS();
});
